<template>
    <div class="main-wrapper">
        <div style="text-align: right">
            <el-button type="primary" @click="toAddPlacement">添加投放位置</el-button>
        </div>
        <el-table :data="placementList" class="customTable" style="width: 100%; margin-top: 15px; flex: 1" height="1%"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="advertising_name" label="投放位置名称"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" @click="editPlacement(scope.row.id)">编辑</el-link>
                    <el-link type="danger" @click="deletePlacement(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0 30px"
                       :current-page="placementPages.currentPageNum"
                       :page-size="placementPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="placementPages.total"
                       @current-change="placementCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {advertisingList, advertisingDel} from '@/utils/apis'
    export default {
        name: "Placement",
        data() {
            return {
                //投放位置列表
                placementList:[],
                //分页
                placementPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                }
            }
        },
        created() {
            this.getPlacementList();
        },
        methods: {
            //获取投放位置列表
            getPlacementList() {
                let param = {
                    limit: this.placementPages.eachPageNum,
                    page: this.placementPages.currentPageNum,
                    status: '1'
                }
                advertisingList(param).then(res => {
                    this.placementList = res.data.data;
                    this.placementPages.total = res.data.total;
                }).catch(err => {
                    console.log(err);
                })
            },
            //添加投放位置
            toAddPlacement() {
                this.$router.push('/practice/placementCreate');
            },
            //编辑
            editPlacement(id) {
                this.$router.push({
                    path: '/practice/placementCreate',
                    query: {
                        id: id
                    }
                });
            },
            //删除
            deletePlacement(id) {
                this.$confirm(`是否删除该投放位置，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type:'warning'
                }).then(()=>{
                    let param = {
                        id: id
                    }
                    advertisingDel(param).then((res) => {
                        this.$message.success(res.msg);
                        this.getPlacementList();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //分页
            placementCurrentChange(val) {
                this.placementPages.currentPageNum = val;
                this.getPlacementList();
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }
</style>